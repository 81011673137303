<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="3" v-for="index in 18" :key="index">
        <v-card class="mx-auto pointer" max-width="500">
          <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
            height="200px"
            @click="openVideo(index)"
          ></v-img>

          <v-card-title> Video {{ index }} </v-card-title>

          <v-card-subtitle> Beschreibung Video {{ index }} </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="white" class="infront">
            <v-btn icon dark @click="closeVideo">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="blackText">{{ title }}</v-toolbar-title>
          </v-toolbar>
          <video
            controls
            ref="video"
            class="fullSize"
            disablePictureInPicture
            controlsList="nodownload noremoteplayback nofullscreen "
          >
            <source src="trailer_480p.mov" type="video/mp4" />
          </video>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    dialog: false,
    title: null,
  }),
  methods: {
    closeVideo() {
      this.$refs.video.pause();
      this.$refs.video.currentTime = 0;
      this.dialog = false;
    },
    openVideo(index) {
      this.title = "Video " + index + " - Beschreibung Video " + index;
      this.dialog = true;
      setTimeout(() => {
        this.$refs.video.play();
      }, 500);
    },
  },
};
</script>

<style scoped>
.blackText {
  color: #000
}
.pointer {
  cursor: pointer;
}
.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullSize {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.infront {
  z-index: 2;
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
video::-webkit-media-controls-play-button {
}
video::-webkit-media-controls-timeline {
}
video::-webkit-media-controls-current-time-display {
}
video::-webkit-media-controls-time-remaining-display {
}
video::-webkit-media-controls-mute-button {
}
video::-webkit-media-controls-toggle-closed-captions-button {
}
video::-webkit-media-controls-volume-slider {
}
</style>